import { clx } from "../../../../helpers/components"
import { Button } from "../../../ui/Button"
import { Checkbox } from "../../../ui/Checkbox"
import { Text } from "../../../ui/Text"
import { PhoneField, SelectBox, TextField } from "../../../ui/TextField"
import style from "./style.module.scss"
import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useRef, useContext, useMemo, useEffect } from "react"
import TawkTo from 'tawkto-react'
import Joi from "joi"
import { MdWarning } from "react-icons/md"
import { COUNTRIES, getCountryFlagURL } from "../../../../helpers/coutriesHelper"
// import { useMemo } from "react"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { MdCheck, MdClose } from "react-icons/md"
// import { useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
// import { useContext } from "react"
import { RecaptchaContext } from "../../RecaptchaProvider"
import axios from "axios"
import { Alert } from "../../Alert"
import { AUTH_TOKEN_KEY, login, redirectToSecure, setCookie, isConnected,csrfTokenCheck, BASE_URL } from "../../../../helpers/func"
import { StoreContext } from "../../AppProvider"

const getMessageOn = (messages, path) => {
    if (typeof path === "string" && !path.includes(" ") && path.includes(".")) {
        let result = null
        eval(`  result=messages.${path};`)
        return result;
    }
    return path
}

const tawktoChat = () => {
    const propertyId = "5b1111ae10b99c7b36d48922"
    const tawkId = "default"

    // const location = useLocation();

    var tawk = new TawkTo(propertyId, tawkId)
    var urlcourante = window.location.href;

    tawk.onStatusChange((status) => {
        if (status === 'online' && urlcourante.includes('?chat')) tawk.toggle()
    })
}

const CheckAndHideFooter = () => {
    const notifExist = localStorage.getItem('notif')
    const ftElement = document.querySelector('#preloader');
    const footers = document.getElementById('footerCustom');
    if (notifExist || !notifExist) {
        if (ftElement)
            ftElement.style.display = 'none';
        if (footers)
            footers.style.display = 'none';
    }
}


const Login = () => {
    const { t, i18n } = useTranslation("translations")

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userParam = searchParams.get('user');

    const stateData = location.state && location.state.state_data;

    const navigate = useNavigate()

    const checkCsrf = async () => {
        // Vérifier si le token CSRF est dans l'URL
        const urlParams = new URLSearchParams(location.search);
        if (!urlParams.get("token") && !(localStorage.getItem('csrf'))) {
            // Si le token n'est pas présent, rediriger vers test.com 
            if (typeof window !== 'undefined' && !window.navigator.userAgent.includes('ReactSnap')) {
                window.location.href = BASE_URL + "?expired_token"
            }
            return;
        }
    };

    useEffect(() => {
        checkCsrf()
    }, [])
    const messages = {
        validations: {
            email: t("login-page.validations.email"),
            password: t("login-page.validations.password"),
            formInvalid: t("login-page.validations.formInvalid"),
            emailOrPassword: t("login-page.validations.emailOrPassword"),
            successfulLogin: t("login-page.validations.successfulLogin"),
            unexpectedError: t("login-page.validations.unexpectedError"),
            recaptcha: t("login-page.validations.recaptcha"),
            csrf: t("login-page.validations.csrf"),
            accountLocked: t("login-page.validations.accountLocked")
        }
    }

    const { apiKey } = useContext(RecaptchaContext)

    // const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true' || false)
    const [loading, setLoading] = React.useState(false)
    const [form, setForm] = React.useState({
        email: {
            value: "",
            isValid: true,
            errorMessage: "validations.email"
        },
        password: {
            value: "",
            showPassword: false,
            isValid: true,
            errorMessage: "validations.password"

        },
        showGroupError: null,
        showGroupSuccess: null
    })

    const handlers = {
        email: (value, returnOnly = false) => {
            if (returnOnly) {
                return ({
                    ...form.email,
                    value,
                    isValid: isEmailValid(value)
                })
            } else {
                const r = isEmailValid(value)
                handleForm("email", value, {
                    isValid: r
                })
                return r
            }
        },

        password: (value, returnOnly = false) => {
            if (returnOnly) {
                return ({
                    ...form.password,
                    value,
                    isValid: Boolean(value)
                })
            } else {
                const r = Boolean(value)
                handleForm("password", value, {
                    isValid: r
                })
                return r
            }
        },
    }

    const handleForm = (key, value, props) => {
        setForm(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                ...props,
                value
            },

        }))
    }

    const errorElementRef = useRef()

    const scrollToTargetAdjusted = () => {
        if (errorElementRef.current) {
            var element = errorElementRef.current
            var headerOffset = 70;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    const isEmailValid = (value) => {
        const { error } = Joi.string().min(1).email({ tlds: false }).validate(value)
        return !Boolean(error)
    }

    const isFormValid = () => {
        const email = handlers.email(form.email.value, true)
        const password = handlers.password(form.password.value, true)
        const data = {
            ...form,
            email,
            password
        }
        setForm(prev => ({
            ...prev,
            ...data,
            showGroupError: !(password.isValid && email.isValid) ? "validations.formInvalid" : null,
            showGroupSuccess: null
        }))

        return password.isValid && email.isValid;
    }

    const handleSubmit = () => {
        if (isFormValid() && apiKey) {
            setLoading(true)
            window.grecaptcha.ready(function callback() {
                setLoading(true)
                window.grecaptcha.execute(apiKey, { action: 'submit' }).then((token) => {
                    loginUser(form.email.value, form.password.value, token)
                }).catch((error) => { setLoading(false) })
            })
            setLoading(false)
        }

    }

    useEffect(() => {
        CheckAndHideFooter();
    }, [])




    // Vérification de la connexion de l'utilisateur au moment du rendu
    React.useEffect(() => {
        setLoading(true)
        isConnected(i18n?.language).then((res) => { setLoading(res) })
            .finally(() => { setLoading(false) })
    }, []);

    const loginUser = async (email, password, token = null) => {
        if(!csrfTokenCheck(localStorage.getItem('csrf'))){
            if (typeof window !== 'undefined' && !window.navigator.userAgent.includes('ReactSnap')) {
                window.location.href = BASE_URL + "?expired_token";
            }
            return;
        }
        setLoading(true);
        login(email, password, localStorage.getItem('csrf'), token, i18n.language)
            .then(({ data }) => {
                setLoading(false)
                if (!data.succes) {
                    if (data.recaptcha) {
                        setForm(prev => ({
                            ...prev,
                            showGroupError: "validations.recaptcha",

                        }))

                    } else if (data.statusCode == "INVALID_FORM") {
                        setForm(prev => ({
                            ...prev,
                            showGroupError: "validations.csrf",
                        }))
                        setTimeout(() => {
                            window.location.href = BASE_URL
                        }, 5000);
                    } else if (data.data?.locked_state == 100) {
                        setForm(prev => ({
                            ...prev,
                            showGroupError: "validations.accountLocked",

                        }))
                    } else {
                        setForm(prev => ({
                            ...prev,
                            showGroupError: "validations.emailOrPassword",

                        }))
                    }


                } else {
                    if (data.temp_token) {
                        navigate("/a2f", {
                            state: {
                                auth_token: data.temp_token
                            }
                        })
                        return
                    } else if (data.tmp_auth_token) {
                        navigate("/check_a2f", {
                            state: {
                                auth_token: data.tmp_auth_token
                            }
                        })
                        return
                    }

                    setForm(prev => ({
                        ...prev,
                        showGroupError: null,
                        showGroupSuccess: "validations.successfulLogin",
                    }))

                    if (data.data.statut === -1 && data.confirm_token) {
                        navigate("/email-confirmation", {
                            state: {
                                auth_token: data.confirm_token
                            }
                        });
                        return;
                    } else if (data.token) {
                        setLoading(true);
                        setCookie(AUTH_TOKEN_KEY, data.token);
                        redirectToSecure("dashboard/pu_echange", i18n.language, true, false, data.token, email);
                    }

                    //window.location.href = "https://izichange.com/dashboard/pu_echange?access_token="+data.token
                }
            })
            .catch(e => {
                setLoading(false)
                setForm(prev => ({
                    ...prev,
                    showGroupSuccess: null,
                    showGroupError: "validations.unexpectedError"
                }))

            })
    }



    React.useEffect(() => {
        if (form.showGroupError && errorElementRef.current) {
            scrollToTargetAdjusted()
        }
    }, [form?.showGroupError, errorElementRef?.current])

    useEffect(() => {
        tawktoChat()
    }, [])

    const titleStyle = {
        color: 'red',
        marginTop: 10
    }

    return (<form onSubmit={(e) => {
        e.preventDefault()
        if (!loading) handleSubmit()
    }}>

        <div className={clx(style.formContainer, "container")}>
            <>
                <div>
                    <Text variant="h3">{t("login-page.title")}</Text>
                    {userParam === 'exist' && <p style={titleStyle}>{t("login-page.alert")}</p>}
                    {stateData && (
                        <p style={{ color: '#008080', paddingTop: 10 }}>{t("resetreq-page.recoveryPasswordSent")}</p>
                    )}
                </div>
                <div ref={errorElementRef}>
                    <Alert theme={form.showGroupError ? "danger" : "success"} open={!!(form.showGroupError || form.showGroupSuccess)} onClose={(e) => {
                        e.preventDefault();
                        setForm(prev => ({ ...prev, [form.showGroupError ? "showGroupError" : "showGroupSuccess"]: false }))
                    }}>
                        <p className="" style={{ width: "100%" }} dangerouslySetInnerHTML={{
                            __html: `<span>${getMessageOn(messages, form.showGroupError ?? form.showGroupSuccess)}</span>`
                        }}>
                        </p>
                    </Alert>
                </div>
                <div className={clx(style.form)}>
                    <TextField
                        type="email"
                        label={t("login-page.form.email.label")}
                        placeholder={t("login-page.form.email.placeholder")}
                        error={!form.email.isValid}
                        errorMessage={getMessageOn(messages, form.email.errorMessage)}
                        onChange={(e) => {
                            handleForm("email", e.target.value, {
                                isValid: true
                            })
                        }}
                        onBlur={() => {
                            handlers.email(form.email.value)

                        }}
                    />
                    <TextField
                        type={form.password.showPassword ? "text" : "password"}
                        label={t("login-page.form.password.label")}
                        placeholder={t("login-page.form.password.placeholder")}
                        value={form.password.value}
                        error={!form.password.isValid}
                        errorMessage={getMessageOn(messages, form.password.errorMessage)}
                        onChange={(e) => {
                            handleForm("password", e.target.value, {
                                isValid: true
                            })
                        }}
                        onBlur={() => {
                            handlers.password(form.password.value)


                        }}
                        endAdornment={
                            <span className={clx(style.adornmentGroup)}>
                                {
                                    form.password.value.length ? (
                                        <span onClick={() => setForm(prev => ({ ...prev, password: { ...prev.password, showPassword: !prev.password.showPassword } }))} className={clx(style.showPassword)}>
                                            {
                                                form.password.showPassword ? <FiEyeOff /> : <FiEye />
                                            }

                                        </span>
                                    ) : <></>
                                }
                            </span>

                        }
                    />
                    <div className={style.mdpForgotten}>
                        <Link to="/reset" style={{ textDecoration: "none" }}>
                            <Text className={style.a}>
                                {t("login-page.passwordForgottentLink")}
                            </Text>
                        </Link>

                    </div>
                    <Button loading={loading} className={style.ctaButton} type="submit" ariaLabel="submit">
                        {t("login-page.ctaButton")}
                    </Button>
                    <div className={style.ctaRegister}>
                        <Link to="/sign-up" style={{ textDecoration: "none" }}>

                            <Text className={style.a}>
                                <span> {t("login-page.noAccountText-1")}</span> <span className="bolded">{t("login-page.noAccountText-2")}</span>
                            </Text>
                        </Link>
                    </div>
                </div>
            </>

        </div>
    </form>)
}
//auth google authentication if token exist
const A2fF = () => {
    const { t, i18n } = useTranslation("translations")

    // const navigate = useNavigate()
    const location = useLocation();
    // Extract the token from the location state
    const auth_token = location.state?.auth_token || "";

    useEffect(() => {
        CheckAndHideFooter()
    }, [])

    React.useEffect(() => {
        if (!auth_token) {
            window.open("/login", '_self');
        }
    }, [auth_token]);

    const [inputData, setInputData] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = React.useState(false)
    const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true' || false)


    const handleSubmit = () => {

        if (inputData) {
            // window.grecaptcha.ready(function () {
            //     window.grecaptcha.execute(apiKey, { action: 'submit' }).then(function (token) {
            a2f(inputData, auth_token)
            //     });
            // });


        }
    }

    const a2f = (code, authToken) => {
        const params = new URLSearchParams();

        params.append('code', code);
        params.append('token', authToken);
        setLoading(true)

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'APPVERSION': "2.0.1"
            },
            data: params,
            url: BASE_URL + "/api/pu_verification_code"
        };

        axios(options)
            .then(({ data }) => {
                if (data.succes) {
                    setLoading(true)
                    setCookie(AUTH_TOKEN_KEY, data.token)
                    redirectToSecure("dashboard/pu_echange", i18n.language, true, false);
                } else {

                }
            })
            .catch(e => {
                setError(t('af-page.validations.validCode'))
            })
            .finally(() => setLoading(false))

    }



    return (
        <>
            <form style={{ width: '50%' }} onSubmit={(e) => {
                e.preventDefault()
                if (inputData === '') { setError(t('af-page.validations.codeEmpty')) }
                if (!loading && inputData !== '') setError(''); handleSubmit()
            }}>
                <div className={clx(style.form)}>
                    <h2>{t('af-page.title')}</h2>
                    {error && <p className={style.errorMsg}>{error}</p>}
                    <TextField
                        label={t('af-page.form.label')}
                        placeholder={t('af-page.form.placeholder')}
                        type="text"
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                    />
                    <Button
                        type="submit"
                        loading={loading}
                        onClick={handleSubmit}
                        className={style.ctaButton}
                        ariaLabel={t('af-page.form.placeholder')}

                    >
                        {t('af-page.form.placeholder')}
                    </Button>

                </div>
            </form>
        </>
    )
}

const ForgotPass = () => {
    const { t, i18n } = useTranslation("translations")
    const [form, setForm] = React.useState({
        email: {
            value: "",
            isValid: true,
            errorMessage: "resetreq-page.validations.email"
        },
        showGroupError: null,
        showGroupSuccess: null
    })

    // const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading') === 'true' || false)



    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)

    const handlers = {
        email: (value, returnOnly = false) => {
            if (returnOnly) {
                return ({
                    ...form.email,
                    value,
                    isValid: isEmailValid(value)
                })
            } else {
                const r = isEmailValid(value)
                handleForm("email", value, {
                    isValid: r
                })
                return r
            }


        },
    }
    const handleForm = (key, value, props) => {
        setForm(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                ...props,
                value
            },

        }))
    }

    const errorElementRef = useRef()

    const scrollToTargetAdjusted = () => {
        if (errorElementRef.current) {
            var element = errorElementRef.current
            var headerOffset = 70;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    const isEmailValid = (value) => {
        const { error } = Joi.string().email({ tlds: false }).validate(value)
        return !Boolean(error)
    }

    const isFormValid = () => {
        const email = handlers.email(form.email.value, true)
        const data = {
            ...form,
            email,
        }

        setForm(prev => ({
            ...prev,
            ...data,
            showGroupError: !(email.isValid) ? "resetreq-page.validations.formInvalid" : null,
            showGroupSuccess: null
        }))
        return (email.isValid)
    }

    const handleSubmit = () => {
        if (isFormValid()) {
            setLoading(true)

            const params = new URLSearchParams();

            params.append('email', form.email.value);

            const options = {
                Accept: 'application/json',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'lang': i18n.language,
                    'appversion': "2.0.1"
                },
                data: params,
                url: BASE_URL + "/api/pu_passrecover"
            };

            axios(options)
                .then(({ data }) => {
                    if (data.succes) {
                        setForm((prev) => ({
                            ...prev,
                            showGroupSuccess: navigate("/login", {
                                state: {
                                    state_data: "resetreq-page.recoveryPasswordSent"
                                }
                            }),
                            showGroupError: null
                        }))
                    } else {
                        setForm((prev) => ({
                            ...prev,
                            showGroupError: "resetreq-page.recoveryPasswordFailed",
                            showGroupSuccess: null
                        }))
                    }
                })
                .catch(e => {
                    setForm((prev) => ({
                        ...prev,
                        showGroupError: "resetreq-page.validations.unexpectedError",
                        showGroupSuccess: null
                    }))
                })
                .finally(() => setLoading(false))
        }
    }

    React.useEffect(() => {
        CheckAndHideFooter()
    }, [])

    React.useEffect(() => {
        tawktoChat()
    }, [])

    React.useEffect(() => {
        if (form.showGroupError && errorElementRef.current) {
            scrollToTargetAdjusted()
        }
    }, [form.showGroupError, form.showGroupSuccess, errorElementRef.current])

    return (
        <form onSubmit={(e) => {
            e.preventDefault()
            if (!loading) handleSubmit()
        }}>

            <div className={clx(style.formContainer, "container")}>
                <>
                    <div>
                        <Text variant="h3">{t("resetreq-page.title")}</Text>
                    </div>
                    <div ref={errorElementRef}>
                        <Alert theme={form.showGroupError ? "danger" : "success"} open={!!(form.showGroupError || form.showGroupSuccess)} onClose={(e) => {
                            e.preventDefault()
                            setForm(prev => ({ ...prev, [form.showGroupError ? "showGroupError" : "showGroupSuccess"]: false }))
                        }}>
                            <p className="" style={{ width: "100%" }}>{t(form.showGroupError ?? form.showGroupSuccess)}</p>
                        </Alert>
                    </div>
                    <div className={clx(style.form)}>
                        <TextField
                            label={t("resetreq-page.form.email.label")}
                            placeholder={t("resetreq-page.form.email.placeholder")}
                            error={!form.email.isValid}
                            errorMessage={t(form.email.errorMessage)}
                            onChange={(e) => {
                                handleForm("email", e.target.value, {
                                    isValid: true
                                })
                            }}
                            onBlur={() => {
                                handlers.email(form.email.value)

                            }}
                        />
                        <Button loading={loading} className={style.ctaButton} onClick={handleSubmit} ariaLabel={t("resetreq-page.ctaButton")}>
                            {t("resetreq-page.ctaButton")}
                        </Button>
                        <div className={style.ctaRegister}>
                            <Link to="/login" style={{ textDecoration: "none" }}>

                                <Text className={style.a}>
                                    <span>

                                        {t("resetreq-page.account-1")}
                                    </span> <span className="bolded">{t("resetreq-page.account-2")}</span>
                                </Text>
                            </Link>
                        </div>
                    </div>
                </>

            </div>
        </form>

    )
}

const SignUpF = () => {
    const urlParams = new URLSearchParams(window.location.search.split('?')[1]);
    const { t, i18n } = useTranslation("translations")
    const { apiKey } = useContext(RecaptchaContext)

    const [isWhatsappNum, setIsWhatsappNum] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsWhatsappNum(e.target.checked);
    }


    useEffect(() => {
        CheckAndHideFooter();
    }, [])

    const messages = {
        validations: {
            email: t("signup-page.validations.email"),
            emailEmpty: t("signup-page.validations.emailEmpty"),
            password: t("signup-page.validations.password"),
            passwordEmpty: t("signup-page.validations.passwordEmpty"),
            formInvalid: t("signup-page.validations.formInvalid"),
            phone: t("signup-page.validations.phone"),
            phoneEmpty: t("signup-page.validations.phoneEmpty"),
            phoneWhatsapp: t("signup-page.validations.phoneWhatsapp"),
            phoneWhatsappEmpty: t("signup-page.validations.phoneWhatsappEmpty"),
            pays: t("signup-page.validations.pays"),
            acceptConditions: t("signup-page.validations.acceptConditions"),
            acceptPolitiqueDeConfidentialite: t("signup-page.validations.acceptPolitiqueDeConfidentialite"),
            passwordRequirement_1: t("signup-page.validations.passwordRequirement_1"),
            passwordRequirement_2: t("signup-page.validations.passwordRequirement_2"),
            passwordRequirement_3: t("signup-page.validations.passwordRequirement_3"),
            passwordRequirement_4: t("signup-page.validations.passwordRequirement_4"),
            // formInvalid: t("signup-page.validations.formInvalid"),
            unexpectedError: t("signup-page.validations.unexpectedError"),
            recaptcha: t("signup-page.validations.recaptcha"),
        }
    }
    const [loading, setLoading] = React.useState(false)
    const location = useLocation()

    const { newUserEmail } = location.state ?? {}

    const [form, setForm] = React.useState({
        email: {
            value: "",
            type: "email",
            validationMessage: "validations.email",
            isValid: null,
            errorMessage: "validations.email",
            showError: false
        },
        phone: {
            value: "",
            type: "tel",
            validationMessage: "validations.phone",
            isValid: null,
            errorMessage: "validations.phone",
            showError: false
        },
        phoneWhatsapp: {
            value: "",
            type: "tel",
            validationMessage: "validations.phoneWhatsapp",
            isValid: null,
            errorMessage: "validations.phoneWhatsapp",
            showError: false
        },
        pays: {
            value: null,
            validationMessage: "validations.pays",
            isValid: null,
            errorMessage: "validations.pays",
            showError: false
        },
        password: {
            value: "",
            type: "password",
            validationMessage: "validations.password",
            isValid: null,
            errorMessage: "validations.password",
            validations: [],
            showError: false,
            showPassword: false
        },
        acceptConditions: {
            value: false,
            validationMessage: "validations.acceptConditions",
            isValid: null,
            errorMessage: "validations.acceptConditions",
            showError: false
        },
        acceptPolitiqueDeConfidentialite: {
            value: false,
            validationMessage: "validations.acceptPolitiqueDeConfidentialite",
            isValid: null,
            errorMessage: "validations.acceptPolitiqueDeConfidentialite",
            showError: false
        },
        showGroupError: false,
        showGroupSuccessMessage: false
    })

    const errorElementRef = useRef()

    const handleForm = (key, value, showError = null, props) => {
        setForm((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                value,
                showError: showError !== null ? showError : Boolean(props?.isValid),
                ...props
            }
        }))
    }

    const validatePassword = (value, evalValidationCallback = null) => {
        const errors = {
            length: {
                errorMessage: "validations.passwordRequirement_1",
                isValid: evalValidationCallback instanceof Function ? evalValidationCallback("length", value) : value.toString().length >= 8
            },
            lowercase: {
                errorMessage: "validations.passwordRequirement_2",
                isValid: evalValidationCallback instanceof Function ? evalValidationCallback("lowercase", value) : /[a-z]/.test(value)
            },
            uppercase: {
                errorMessage: "validations.passwordRequirement_3",
                isValid: evalValidationCallback instanceof Function ? evalValidationCallback("uppercase", value) : /[A-Z]/.test(value)
            },
            numeric: {
                errorMessage: "validations.passwordRequirement_4",
                isValid: evalValidationCallback instanceof Function ? evalValidationCallback("numeric", value) : /[0-9]/.test(value)
            }
        }
        return {
            isValid: !Object.values(errors).find((current) => !current.isValid),
            errors
        }
    }


    const validateForm = () => {
        let isFormValid = true
        let data = {}
        for (let key in form) {
            if (!/group/i.test(key)) {
                if (key === "phoneWhatsapp" && isWhatsappNum) {
                    data[key] = {
                        ...form[key],
                        isValid: true,
                        showError: false,
                    };
                } else {
                    data[key] = {
                        ...form[key],
                        ...handlers[key](form[key].value, true, {}, true),
                    }
                    if (isFormValid && !form[key].isValid) {
                        isFormValid = false
                    }
                }

            }
        }
        setForm(prev => ({
            ...prev,
            ...data,
            showGroupError: !isFormValid,
            showGroupSuccessMessage: null,
        }))
        return isFormValid
    }

    const getGroupErrors = (errors) => {
        return (
            <ul className="">
                {errors.map((e, i) => <li key={i}>{e}</li>)}
            </ul>
        )
    }

    const navigate = useNavigate()

    const handleSubmitSignUp = () => {
        if (validateForm() && apiKey) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(apiKey)
                    .then((token) => {
                        signup(token, apiKey)
                    }).catch((error) => { })
            });
        }
    }

    const signup = (token, apiKey) => {
        setLoading(true);
        const params = new URLSearchParams();

        params.append('residence', form.pays.value.value.toLowerCase());
        params.append("email", form.email.value);
        params.append("full_contact", form.phone.value);
        params.append("password", form.password.value);
        params.append("full_numeroWhatsapp", isWhatsappNum ? form.phone.value : form.phoneWhatsapp.value);
        params.append("token", token);
        params.append("parrain_id", urlParams.get('ref'));

        const options = {
            Accept: 'application/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'lang': i18n.language,
                'appversion': "2.0.1"
            },
            data: params,
            url: BASE_URL + "/api/pu_signup/web"
        };

        axios(options)
            .then(({ data }) => {
                if (data.succes) {
                    if (data.continue) {
                        setLoading(false)
                        navigate({
                            pathname: "/login",
                            search: `?user=exist`
                        });
                    }
                    else
                        window.grecaptcha.execute(apiKey, { action: 'submit' }).then((_token) => {
                            login(form.email.value, form.password.value, localStorage.getItem('csrf'), _token, i18n.language).then(({ data }) => {
                                if (data.succes) {
                                    navigate("/email-confirmation", {
                                        state: {
                                            auth_token: data.confirm_token
                                        }
                                    })
                                } else {
                                    setForm(prev => ({
                                        ...prev,
                                        showGroupError: null,
                                        showGroupSuccessMessage: t("signup-page.accountCreated")
                                    }))
                                    setTimeout(() => {
                                        navigate("/login")
                                    }, [16000])
                                }
                            })
                        }).catch((e) => {
                            setForm(prev => ({
                                ...prev,
                                showGroupError: null,
                                showGroupSuccessMessage: t("signup-page.accountCreated")
                            }))
                            setTimeout(() => {
                                navigate("/login")
                            }, [16000])
                        });
                } else {
                    setLoading(false)
                    if (data.recaptcha) {
                        setForm(prev => ({ ...prev, showGroupError: "validations.recaptcha" }))
                    } else { 
                        if (data.message){ 
                            setForm((prev => ({
                                ...prev,
                                showGroupError: data.message
                            })))
                        }else if (data.errors) {
                            const errors = data.errors
                            const forms = {
                                email: form.email,
                                password: form.password,
                                showGroupError: getGroupErrors(Object.values(errors))
                            }
                            if (errors.exist || errors.email) {
                                forms.email = handlers.email(
                                    form.email.value,
                                    true,
                                    {
                                        isValid: false,
                                        errorMessage: errors.exist || errors.email
                                    },
                                    true

                                )
                            }else if (errors.pattern) {
                                const { isValid, errors: validations } = validatePassword(form.password.value, () => false)
                                forms.password = handlers.password(
                                    form.password.value,
                                    true,
                                    {
                                        validations: Object.values(validations),
                                        errorMessage: errors.pattern,
                                        isValid
                                    },
                                    true
                                )
                            }
                            setForm((prev => ({
                                ...prev,
                                ...forms
                            })))
                        }else{
                            setForm(prev => ({ ...prev, showGroupError: "validations.unexpectedError" }));
                        }
                    }
                }
            }).catch(e => { 
                setLoading(false)
                setForm(prev => ({ ...prev, showGroupError: "validations.unexpectedError" }));
            })
    }

    const handlers = {
        email: (value, showValid = null, props, returnOnly = false) => {
            const { error } = Joi.string().email({ tlds: false }).validate(value)
            const errorMessage = (Boolean(value) ? (error ? form.email.validationMessage : "") : "validations.emailEmpty")
            if (returnOnly) {
                return {
                    value,
                    showError: showValid,
                    isValid: !Boolean(error),
                    errorMessage,
                    ...props
                }
            } else {
                const r = !Boolean(error)
                handleForm("email", value, showValid, {

                    isValid: r,
                    errorMessage,
                    ...props,
                })
                return r
            }

        },
        phone: (value, showValid = null, props, returnOnly = false) => {
            const errorMessage = Boolean(value) ? (showValid ? form.phone.validationMessage : "") : "validations.phoneEmpty"
            if (returnOnly) {
                return {
                    value,
                    showError: showValid,
                    errorMessage,
                    ...props
                }
            } else {
                handleForm("phone", value, showValid, { errorMessage, ...props })
            }

            return Boolean(showValid)
        },
        phoneWhatsapp: (value, showValid = null, props, returnOnly = false) => {
            const errorMessage = Boolean(value) ? (showValid ? form.phoneWhatsapp.validationMessage : "") : "validations.phoneWhatsappEmpty"
            if (returnOnly) {
                return {
                    value,
                    showError: showValid,
                    errorMessage,
                    ...props
                }
            } else {
                handleForm("phoneWhatsapp", value, showValid, { errorMessage, ...props })
            }

            return Boolean(showValid)
        },
        pays: (value, showValid = null, props, returnOnly = false) => {
            const errorMessage = Boolean(value) ? "" : form.pays.validationMessage
            if (returnOnly) {
                return {
                    value,
                    showError: showValid,
                    isValid: Boolean(value),
                    errorMessage,
                    ...props
                }
            } else {
                const r = Boolean(value)
                handleForm("pays", value, showValid, {
                    isValid: r,
                    errorMessage,
                    ...props
                })
                return r
            }

        },
        password: (value, showError = null, props, returnOnly = false) => {
            const { isValid, errors } = validatePassword(value)
            const errorMessage = Boolean(value) ? (isValid ? (form.password.validationMessage ?? "validations.password") : "") : "validations.passwordEmpty"
            if (returnOnly) {
                return {
                    value,
                    showError: showError === null ? isValid : showError,
                    isValid,
                    validations: !isValid ? Object.values(errors) : [],
                    errorMessage,
                    ...props
                }
            } else {
                handleForm("password", value, showError === null ? isValid : showError, {
                    validations: Object.values(errors),
                    isValid,
                    errorMessage,
                    ...props
                })
            }
            return isValid
        },
        acceptConditions: (value, showError = true, props, returnOnly = false) => {
            // const errorMessage = Boolean(value) ? "" : form.acceptConditions.validationMessage
            if (returnOnly) {
                return {
                    value,
                    showError,
                    ...props
                }
            } else {
                const r = Boolean(value)
                handleForm("acceptConditions", value, showError, {
                    isValid: r,
                    ...props
                })
                return r;
            }

        },
        acceptPolitiqueDeConfidentialite: (value, showError = true, props, returnOnly = false) => {
            // const errorMessage = Boolean(value) ? "" : form.acceptPolitiqueDeConfidentialite.validationMessage
            if (returnOnly) {
                return {
                    value,
                    showError,
                    ...props
                }
            } else {
                const r = Boolean(value)
                handleForm("acceptPolitiqueDeConfidentialite", value, showError, {
                    isValid: r,
                    ...props
                })
                return r;
            }

        }

    }

    const scrollToTargetAdjusted = () => {
        if (errorElementRef.current) {
            var element = errorElementRef.current
            var headerOffset = 70;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    const listCountries = useMemo(() => (
        COUNTRIES.map((c, i) => ({ label: c.name, value: c.code, data: c }))
    ), [])

    const handleAcceptConditionsClick = () => {
        // navigate("/general-conditions")
    }
    const handlePolitiqueDeConfidentialite = () => {
        // navigate("/politique-confidentialite")
    }

    React.useEffect(() => {
        if (newUserEmail) {
            handlers.email(newUserEmail, true)
        }
    }, [newUserEmail])

    React.useEffect(() => {
        if ((form.showGroupError || form.showGroupSuccessMessage) && errorElementRef.current) {
            scrollToTargetAdjusted()
        }
    }, [form.showGroupError, form.showGroupSuccessMessage, errorElementRef.current])

    React.useEffect(() => {
        tawktoChat()
    }, [])

    return (
        <form onSubmit={(e) => {
            e.preventDefault()
            if (!loading) {

                handleSubmitSignUp()
            }

        }}>
            <div className={clx(style.form)}>
                <div ref={errorElementRef}>
                    <Alert
                        open={form.showGroupError || form.showGroupSuccessMessage}
                        theme={form.showGroupError ? "danger" : "success"}
                        onClose={(e) => {
                            e.preventDefault()
                            setForm(prev => ({ ...prev, [form.showGroupError ? "showGroupError" : "showGroupSuccessMessage"]: false }))
                        }}
                    >
                        {getMessageOn(messages, typeof form.showGroupError === "boolean" ? messages.validations.formInvalid : (form.showGroupError || form.showGroupSuccessMessage))}
                    </Alert>

                </div>
                <TextField
                    label={t('signup-page.form.email.label')}
                    placeholder={t('signup-page.form.email.placeholder')}
                    type="email"
                    endAdornment={(
                        form.email.showError && (
                            <span className={style.endornment}>
                                {
                                    form.email.isValid ? (
                                        <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="#008080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    ) : <MdWarning color="var(--izi-primary-danger-color)" size={"1.5rem"} />
                                }

                            </span>
                        )
                    )}
                    errorMessage={getMessageOn(messages, form.email.errorMessage)}
                    error={form.email.showError ? !form.email.isValid : null}
                    value={form.email.value}
                    onChange={(e) => handlers.email(e.target.value)}
                    onBlur={(e) => handlers.email(form.email.value, true)}
                />
                <PhoneField
                    error={form.phone.showError ? !form.phone.isValid : null}
                    type="tel"
                    label={t('signup-page.form.phone.label')}
                    errorMessage={getMessageOn(messages, form.phone.errorMessage)}
                    value={form.phone.value}
                    onChange={({ value, isValid }) => {
                        handlers.phone(value, isValid, {
                            isValid
                        })
                    }}
                    endAdornment={
                        form.phone.showError && (
                            form.phone.isValid ? (
                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="#008080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            ) : <MdWarning color="var(--izi-primary-danger-color)" size={"1.5rem"} />

                        )
                    }
                    onPhoneNumberBlur={(isValid) => handlers.phone(form.phone.value, true)}

                />
                <div className={style.acceptConditions}>
                    <Checkbox
                        // label={t('signup-page.form.checkLabel.label')}
                        checked={isWhatsappNum}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="" className={style.labCheck}>
                        {t('signup-page.form.checkLabel.label')}
                    </label>
                </div>

                {/* <input type="checkbox" id="whatsapp_num" name="" onChange={handleCheckboxChange} className="checkbox_class" checked={isWhatsappNum}/>
                <label for="whatsapp_num">C'est mon numéro whatsapp</label> */}

                {
                    !isWhatsappNum && <PhoneField
                        error={form.phoneWhatsapp.showError ? !form.phoneWhatsapp.isValid : null}
                        type="tel"
                        label={t('signup-page.form.phoneWhatsapp.label')}
                        errorMessage={getMessageOn(messages, form.phoneWhatsapp.errorMessage)}
                        value={form.phoneWhatsapp.value}
                        onChange={({ value, isValid }) => {
                            handlers.phoneWhatsapp(value, isValid, {
                                isValid
                            })
                        }}
                        endAdornment={
                            form.phoneWhatsapp.showError && (
                                form.phoneWhatsapp.isValid ? (
                                    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="#008080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                ) : <MdWarning color="var(--izi-primary-danger-color)" size={"1.5rem"} />

                            )
                        }
                        onPhoneNumberBlur={(isValid) => handlers.phoneWhatsapp(form.phoneWhatsapp.value, true)}

                    />
                }
                <SelectBox
                    label={t('signup-page.form.pays.label')}
                    options={listCountries}
                    formatOptionLabel={(option) => (
                        <div className={style.option}>
                            <img
                                src={getCountryFlagURL(option.data.code)}
                                alt={option.label}
                            />
                            <span>{option.label}</span>
                        </div>
                    )}
                    errorMessage={getMessageOn(messages, form.pays.errorMessage)}
                    error={form.pays.showError ? !form.pays.isValid : null}
                    value={form.pays.value}
                    onChange={handlers.pays}

                />
                <TextField
                    type={form.password.showPassword ? "text" : "password"}
                    label={t('signup-page.form.password.label')}
                    errorMessage={getMessageOn(messages, form.password.errorMessage)}
                    placeholder={t('signup-page.form.password.placeholder')}
                    value={form.password.value}
                    onChange={(e) => {
                        handlers.password(e.target.value)
                    }}
                    error={form.password.showError ? !form.password.isValid : null}
                    onBlur={(e) => {
                        // const { isValid, errors } = validatePassword(e.target.value)
                        handlers.password(form.password.value, true)
                    }}
                    endAdornment={(
                        <span className={clx(style.adornmentGroup)}>
                            {
                                form.password.value.length ? (
                                    <span onClick={() => setForm(prev => ({ ...prev, password: { ...prev.password, showPassword: !prev.password.showPassword } }))} className={clx(style.showPassword)}>
                                        {
                                            form.password.showPassword ? <FiEyeOff /> : <FiEye />
                                        }

                                    </span>
                                ) : <></>
                            }
                            {
                                form.password.showError && (
                                    <span className={style.endornment}>
                                        {
                                            form.password.isValid ? (
                                                <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="#008080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : <MdWarning color="var(--izi-primary-danger-color)" size={"1.5rem"} />
                                        }

                                    </span>
                                )
                            }

                        </span>
                    )}

                />
                {
                    form.password.validations.length ? (
                        <ul className={style.validations}>
                            {
                                form.password.validations.map((v, i) => (
                                    <li className={style.validationList} key={i}>
                                        {
                                            v.isValid ?
                                                (
                                                    <span className={style.valid} >
                                                        <MdCheck />
                                                    </span>
                                                ) :
                                                (
                                                    <span className={style.notvalid}>
                                                        <MdClose />
                                                    </span>
                                                )
                                        }

                                        <span>
                                            {getMessageOn(messages, v.errorMessage)}
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    ) : <></>
                }

                <div >
                    <div className={style.acceptConditions}>
                        <Checkbox
                            checked={form.acceptConditions.value}
                            onChange={(e) => {
                                handlers.acceptConditions(e.currentTarget.checked)
                            }}
                        />
                        <label htmlFor="acceptConditions" className={style.labCheck}>
                            <a href="/general-conditions" target="_blank">{t('signup-page.form.acceptConditions.label')}</a>
                        </label>
                    </div>
                    {
                        !form.acceptConditions.isValid && form.acceptConditions.showError && (
                            <p className={style.errorText}>{getMessageOn(messages, "validations.acceptConditions")}</p>
                        )
                    }
                </div>
                <div >
                    <div className={style.acceptConditions}>
                        <Checkbox
                            checked={form.acceptPolitiqueDeConfidentialite.value}
                            onChange={(e) => {
                                handlers.acceptPolitiqueDeConfidentialite(e.currentTarget.checked)
                            }}
                        />
                        <label htmlFor="acceptPolitiqueDeConfidentialite" className={style.labCheck}>
                            <a href="/politique-confidentialite" target="_blank">{t('signup-page.form.acceptPolitiqueDeConfidentialite.label')}</a>
                        </label>
                    </div>
                    {
                        !form.acceptPolitiqueDeConfidentialite.isValid && form.acceptPolitiqueDeConfidentialite.showError && (
                            <p className={style.errorText}>{getMessageOn(messages, "validations.acceptPolitiqueDeConfidentialite")}</p>
                        )
                    }
                </div>
                <Button
                    type="submit"
                    loading={loading}
                    className={style.ctaButton}
                    ariaLabel={t('signup-page.ctaButton')}

                >
                    {t('signup-page.ctaButton')}
                </Button>
                <div className={style.ctaRegister}>

                    <Link to="/login" style={{ textDecoration: "none" }}>

                        <Text className={style.a}>
                            <span> {t("signup-page.account-available-1")}</span> <span className="bolded">{t("signup-page.account-available-2")}</span>
                        </Text>
                    </Link>
                </div>
            </div>
        </form>
    )
}

export { Login, A2fF, ForgotPass, SignUpF, CheckAndHideFooter, tawktoChat }


// export const ResetPasswordRequest = () => {
//     const { t, i18n } = useTranslation("translations")
//     const [form, setForm] = React.useState({
//         email: {
//             value: "",
//             isValid: true,
//             errorMessage: "resetreq-page.validations.email"
//         },
//         showGroupError: null,
//         showGroupSuccess: null
//     })

//     const navigate = useNavigate()
//     const [loading, setLoading] = React.useState(false)

//     const handlers = {
//         email: (value, returnOnly = false) => {
//             if (returnOnly) {
//                 return ({
//                     ...form.email,
//                     value,
//                     isValid: isEmailValid(value)
//                 })
//             } else {
//                 const r = isEmailValid(value)
//                 handleForm("email", value, {
//                     isValid: r
//                 })
//                 return r
//             }


//         },
//     }
//     const handleForm = (key, value, props) => {
//         setForm(prev => ({
//             ...prev,
//             [key]: {
//                 ...prev[key],
//                 ...props,
//                 value
//             },

//         }))
//     }

//     const errorElementRef = useRef()

//     function scrollToTargetAdjusted() {
//         if (errorElementRef.current) {
//             var element = errorElementRef.current
//             var headerOffset = 70;
//             var elementPosition = element.getBoundingClientRect().top;
//             var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

//             window.scrollTo({
//                 top: offsetPosition,
//                 behavior: "smooth"
//             });
//         }
//     }

//     const isEmailValid = (value) => {
//         const { error } = Joi.string().email({ tlds: false }).validate(value)
//         return !Boolean(error)
//     }

//     const isFormValid = () => {
//         const email = handlers.email(form.email.value, true)
//         const data = {
//             ...form,
//             email,
//         }

//         setForm(prev => ({
//             ...prev,
//             ...data,
//             showGroupError: !(email.isValid) ? "resetreq-page.validations.formInvalid" : null,
//             showGroupSuccess: null
//         }))
//         return (email.isValid)
//     }

//     const handleSubmit = () => {
//         if (isFormValid()) {
//             setLoading(true)

//             const params = new URLSearchParams();

//             params.append('email', form.email.value);

//             const options = {
//                 Accept: 'application/json',
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                     'lang': i18n.language,
//                     'appversion': "2.0.1"
//                 },
//                 data: params,
//                 url: BASE_URL+"/api/pu_passrecover"
//             };
//             axios(options)
//                 .then(({ data }) => {
//                     if (data.succes) {
//                         setForm((prev) => ({
//                             ...prev,
//                             showGroupSuccess: navigate("/login", {
//                                 state: {
//                                     auth_token: "resetreq-page.recoveryPasswordSent"
//                                 }
//                             }),
//                             showGroupError: null
//                         }))
//                     } else {
//                         setForm((prev) => ({
//                             ...prev,
//                             showGroupError: "resetreq-page.recoveryPasswordFailed",
//                             showGroupSuccess: null
//                         }))
//                     }
//                 })
//                 .catch(e => {
//                     setForm((prev) => ({
//                         ...prev,
//                         showGroupError: "resetreq-page.validations.unexpectedError",
//                         showGroupSuccess: null
//                     }))
//                 })
//                 .finally(() => setLoading(false))
//         }
//     }

//     React.useEffect(() => {
//         if (form.showGroupError && errorElementRef.current) {
//             scrollToTargetAdjusted()
//         }
//     }, [form.showGroupError, form.showGroupSuccess, errorElementRef.current])


//     return (
//         <form onSubmit={(e) => {
//             e.preventDefault()
//             if (!loading) {
//                 handleSubmit()
//             }

//         }}>
//             <div className={clx(style.formContainer, "container")}>
//                 <div>
//                     <Text variant="h3">{t("resetreq-page.title")}</Text>
//                 </div>
//                 <div ref={errorElementRef}>
//                     <Alert theme={form.showGroupError ? "danger" : "success"} open={!!(form.showGroupError || form.showGroupSuccess)} onClose={(e) => {
//                         e.preventDefault()
//                         setForm(prev => ({ ...prev, [form.showGroupError ? "showGroupError" : "showGroupSuccess"]: false }))
//                     }}>
//                         <p className="" style={{ width: "100%" }}>{t(form.showGroupError ?? form.showGroupSuccess)}</p>
//                     </Alert>
//                 </div>
//                 <div className={clx(style.form)}>
//                     <TextField
//                         label={t("resetreq-page.form.email.label")}
//                         placeholder={t("resetreq-page.form.email.placeholder")}
//                         error={!form.email.isValid}
//                         errorMessage={t(form.email.errorMessage)}
//                         onChange={(e) => {
//                             handleForm("email", e.target.value, {
//                                 isValid: true
//                             })
//                         }}
//                         onBlur={() => {
//                             handlers.email(form.email.value)

//                         }}
//                     />
//                     <Button loading={loading} className={style.ctaButton} onClick={handleSubmit} ariaLabel={t("resetreq-page.ctaButton")}>
//                         {t("resetreq-page.ctaButton")}
//                     </Button>
//                     <div className={style.ctaRegister}>
//                         <Link to="/login" style={{ textDecoration: "none" }}>

//                             <Text className={style.a}>
//                                 <span>

//                                     {t("resetreq-page.account-1")}
//                                 </span> <span className="bolded">{t("resetreq-page.account-2")}</span>
//                             </Text>
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </form>

//     )
// }


// export const ResetPassword = ({ reset, hid }) => {
//     const { t, i18n } = useTranslation("translations")
//     const [loading, setLoading] = React.useState(false)
//     const navigate = useNavigate()
//     const [form, setForm] = React.useState({
//         password: {
//             value: "",
//             errorMessage: "reset.validations.password",
//             isValid: null,
//             validations: [],
//             showError: false,
//             showPassword: false
//         },
//         passwordRepeat: {
//             value: "",
//             errorMessage: "reset.validations.passwordRepeat",
//             isValid: null,
//             showError: false,
//             showPassword: false
//         },
//         showGroupError: false,
//         showGroupSuccess: null
//     })

//     const errorElementRef = useRef()

//     const handleForm = (key, value, showError = null, props) => {
//         setForm((prev) => ({
//             ...prev,
//             [key]: {
//                 ...prev[key],
//                 value,
//                 showError: showError !== null ? showError : Boolean(props?.isValid),
//                 ...props
//             }
//         }))
//     }

//     const validatePassword = (value) => {
//         const errors = {
//             length: {
//                 errorMessage: "reset.validations.passwordRequirement_1",
//                 isValid: value.toString().length >= 8
//             },
//             lowercase: {
//                 errorMessage: "reset.validations.passwordRequirement_2",
//                 isValid: /[a-z]/.test(value)
//             },
//             uppercase: {
//                 errorMessage: "reset.validations.passwordRequirement_3",
//                 isValid: /[A-Z]/.test(value)
//             },
//             numeric: {
//                 errorMessage: "reset.validations.passwordRequirement_4",
//                 isValid: /[0-9]/.test(value)
//             }
//         }
//         return {
//             isValid: !Object.values(errors).find((current) => !current.isValid),
//             errors
//         }
//     }


//     const validateForm = () => {
//         let isFormValid = true
//         let data = {}
//         for (let key in form) {
//             if (!key.toLowerCase().includes("group")) {
//                 data[key] = {
//                     ...form[key],
//                     ...handlers[key](form[key].value, true, {}, true),
//                 }
//                 if (isFormValid && !form[key].isValid) {
//                     isFormValid = false
//                 }
//             }
//         }
//         setForm(prev => ({
//             ...prev,
//             ...data,
//             showGroupError: !isFormValid,
//             showGroupSuccess: null
//         }))
//         return isFormValid
//     }

//     const handleSubmit = async () => {
//         if (validateForm()) {
//             setLoading(true)
//             const params = new URLSearchParams();

//             params.append('reset', reset);
//             params.append('hid', hid);
//             params.append('password', form.password.value);
//             params.append('repassword', form.passwordRepeat.value);

//             const options = {
//                 Accept: 'application/json',
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                     'lang': i18n.language,
//                     'appversion': "2.0.1"
//                 },
//                 data: params,
//                 url: BASE_URL+"/api/pu_password_reset"
//             };
//             axios(options)
//                 .then(({ data }) => {
//                     if (data.succes) {
//                         setForm((prev) => ({
//                             ...prev,
//                             showGroupSuccess: "reset.passwordReset",
//                             showGroupError: null
//                         }))
//                         setTimeout(() => {
//                             navigate("/login")
//                         }, 3000)
//                     } else {
//                         setForm((prev) => ({
//                             ...prev,
//                             showGroupError: data.message ?? "reset.passwordResetFailed",
//                             showGroupSuccess: null
//                         }))
//                     }
//                 })
//                 .catch(e => {
//                     setForm((prev) => ({
//                         ...prev,
//                         showGroupError: "reset.validations.unexpectedError",
//                         showGroupSuccess: null
//                     }))
//                 })
//                 .finally(() => setLoading(false))
//         }
//     }

//     const handlers = {
//         password: (value, showError = null, props, returnOnly = false) => {
//             const { isValid, errors } = validatePassword(value)
//             const errorMessage = value.length ? (isValid ? "reset.validations.password" : "") : "reset.validations.passwordEmpty"
//             if (returnOnly) {
//                 return {
//                     value,
//                     showError: showError === null ? isValid : showError,
//                     isValid,
//                     errorMessage,
//                     ...props
//                 }
//             } else {
//                 handleForm("password", value, showError === null ? isValid : showError, {
//                     validations: Object.values(errors),
//                     isValid,
//                     errorMessage,
//                     ...props
//                 })
//             }
//             return isValid
//         },

//         passwordRepeat: (value, showError = null, props, returnOnly = false) => {
//             const isValid = value.length && value === form.password.value
//             const errorMessage = value.length ? (value !== form.password.value ? "reset.validations.passwordRepeat" : "") : "reset.validations.passwordRepeatEmpty"
//             if (returnOnly) {
//                 return {
//                     ...form.passwordRepeat,
//                     value,
//                     showError: showError === null ? isValid : showError,
//                     isValid,
//                     errorMessage,
//                 }
//             } else {
//                 handleForm("passwordRepeat", value, showError === null ? isValid : showError, {
//                     isValid,
//                     errorMessage,
//                     ...props
//                 })
//             }
//             return isValid
//         }

//     }
//     function scrollToTargetAdjusted() {
//         if (errorElementRef.current) {
//             var element = errorElementRef.current
//             var headerOffset = 70;
//             var elementPosition = element.getBoundingClientRect().top;
//             var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

//             window.scrollTo({
//                 top: offsetPosition,
//                 behavior: "smooth"
//             });
//         }
//     }

//     React.useEffect(() => {
//         if (form.showGroupError && errorElementRef.current) {
//             scrollToTargetAdjusted()
//         }
//     }, [form.showGroupError, errorElementRef.current])

//     return (
//         <form onSubmit={(e) => {
//             e.preventDefault()
//             if (!loading) handleSubmit()
//         }}>
//             <div className={clx(style.formContainer, "container")}>
//                 <div>
//                     <Text variant="h3">
//                         {t("reset.title")}

//                     </Text>
//                 </div>
//                 <div className={clx(style.form)}>
//                     <div ref={errorElementRef}>
//                         <Alert theme={form.showGroupError ? "danger" : "success"} open={!!(form.showGroupError || form.showGroupSuccess)} onClose={(e) => {
//                             e.preventDefault();
//                             setForm(prev => ({ ...prev, [form.showGroupError ? "showGroupError" : "showGroupSuccess"]: false }))
//                         }}>
//                             <p className="" style={{ width: "100%" }}>{t(form.showGroupError ?? form.showGroupSuccess)}</p>
//                         </Alert>
//                     </div>

//                     <TextField
//                         type={/*form.password.showPassword ? "text" :*/ "password"}
//                         label={t("reset.form.password.label")}
//                         errorMessage={t(form.password.errorMessage)}
//                         placeholder={t("reset.form.password.placeholder")}
//                         value={form.password.value}
//                         onChange={(e) => {
//                             handlers.password(e.target.value)
//                         }}
//                         error={form.password.showError ? !form.password.isValid : null}
//                         onBlur={(e) => {
//                             const { isValid } = validatePassword(e.target.value)
//                             handleForm("password", form.password.value, true, { isValid, errorMessage: "reset.validations.password" })
//                             handleForm("passwordRepeat", form.passwordRepeat.value, Boolean(form.passwordRepeat.value), { isValid: form.password.value === form.passwordRepeat.value })
//                         }}
//                         endAdornment={null}

//                     />
//                     {
//                         form.password.validations.length ? (
//                             <ul className={style.validations}>
//                                 {
//                                     form.password.validations.map((v, i) => (
//                                         <li className={style.validationList} key={i}>
//                                             {
//                                                 v.isValid ?
//                                                     (
//                                                         <span className={style.valid} >
//                                                             <MdCheck />
//                                                         </span>
//                                                     ) :
//                                                     (
//                                                         <span className={style.notvalid}>
//                                                             <MdClose />
//                                                         </span>
//                                                     )
//                                             }

//                                             <span>
//                                                 {t(v.errorMessage)}
//                                             </span>
//                                         </li>
//                                     ))
//                                 }
//                             </ul>
//                         ) : <></>
//                     }
//                     <TextField
//                         type={form.passwordRepeat.showPassword ? "text" : "password"}
//                         label={t("reset.form.passwordRepeat.label")}
//                         errorMessage={t(form.passwordRepeat.errorMessage)}
//                         placeholder={t("reset.form.passwordRepeat.placeholder")}
//                         value={form.passwordRepeat.value}
//                         onChange={(e) => {
//                             handlers.passwordRepeat(e.target.value)
//                         }}
//                         error={form.passwordRepeat.showError ? !form.passwordRepeat.isValid : null}
//                         onBlur={(e) => {
//                             const isValid = (e.target.value) === form.password.value
//                             handleForm("passwordRepeat", form.passwordRepeat.value, true, { isValid })
//                         }}
//                         endAdornment={/*(
//                         <span className={clx(style.adornmentGroup)}>
//                             {
//                                 form.passwordRepeat.value.length ? (
//                                     <span onClick={() => { setForm(prev => ({ ...prev, passwordRepeat: { ...prev.passwordRepeat, showPassword: !prev.passwordRepeat.showPassword } })) }} className={clx(style.showPassword)}>
//                                         {
//                                             form.passwordRepeat.showPassword ? <FiEyeOff /> : <FiEye />
//                                         }

//                                     </span>
//                                 ) : <></>
//                             }
//                             {
//                                 form.passwordRepeat.showError && (
//                                     <span className={style.endornment}>
//                                         {
//                                             form.passwordRepeat.isValid ? (
//                                                 <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                     <path d="M2.00007 7.10798L8.07986 12.8812L19.2757 2.25" stroke="#008080" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
//                                                 </svg>
//                                             ) : <MdWarning color="var(--izi-primary-danger-color)" size={"1.5rem"} />
//                                         }

//                                     </span>
//                                 )
//                             }

//                         </span>
//                         )*/null}

//                     />



//                     <Button
//                         onClick={handleSubmit}
//                         className={style.ctaButton}
//                         loading={loading}
//                         ariaLabel={t("reset.ctaButton")}
//                     >
//                         {t("reset.ctaButton")}
//                     </Button>
//                 </div>
//             </div>
//         </form>
//     )
// }

